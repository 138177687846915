.main {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 5;
}
