.navBarOuterLight {
  margin: 10px 10px 30px 10px !important;
  border-radius: 20px !important;
  border: 1px black solid;
  background-color: transparent;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.navBar {
  padding: 10px 10px 10px 10px !important;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;

  img {
    height: 3.5em;
    width: 15em;
    border-radius: 10px !important;
  }
}

.navbarMainPart {
  margin-right: 10px;
  justify-content: end;
}
