// Breakpoints
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px

// Media Queries
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

// Font-Size
$base-font-size: 1em;

// Line-Height
$base-line-height: 1.5;
$header-line-height: 1.25;

body {
  margin: 0;
  font-family: "Oxygen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: $base-font-size;
  line-height: $base-line-height;

  @media #{$mq-medium} {
    font-size: $base-font-size * 1.2;
    line-height: $base-line-height * 1.2;
  }

  @media #{$mq-large} {
    font-size: $base-font-size * 1.3;
  }

  @media #{$mq-xlarge} {
    font-size: $base-font-size * 1.4;
  }

  @media #{$mq-xxlarge} {
    font-size: $base-font-size * 1.6;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto Slab", serif;
}

.centered {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.typewriter {
  overflow: hidden;
  border-right: 0.3em solid orange;
  padding-right: 0.3em;
  white-space: nowrap;
  margin: 0 auto;
  animation: typing 2s steps(40, end), blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
