.contactUs {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    text-align: center;

    table {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      border-spacing: 0 10px;

      th {
        vertical-align: top;
      }

      th,
      td {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }
    }
  }
}
