@import "../../core/_colors";

.footer {
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 5px 5px gray;
  margin: 10px;
  padding: 5px;
  cursor: pointer;

  h2 {
    color: $secondary-color;
    font-size: larger;
  }

  &:hover {
    box-shadow: 0px 0px;
  }
}
